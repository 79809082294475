<script setup>
import store from "@/store";
import { computed, onMounted, ref } from "vue";
import { DateTime } from "luxon";
import CardMemory from "@/components/Memory/CardMemory.vue";
import { sampleSize, sample, shuffle, random } from "lodash-es";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import NavigationNew from "@/components/NavigationNew.vue";

const leaderboardData = ref(null);
const loading = ref(false);
const error = ref(null);

const user = computed(() => {
  return store.state.auth.user;
});

const gameData = ref(null);

const currentSelection = ref([]);

const dailyRecord = ref(null);

const getDailyRecord = async (create) => {
  loading.value = true;

  let req;

  try {
    req = await store.dispatch("arcade/arcadeMemoryDaily", { create_day: create });
  } catch (e) {
    console.error(e);
  }

  if (req) {
    dailyRecord.value = req;
  }

  loading.value = false;
};

const startNewGame = async () => {
  loading.value = true;
  let req;

  try {
    req = await store.dispatch("arcade/arcadeMemoryTokens");
  } catch (e) {
    console.error(e);
  }

  totalMoves.value = 0;

  const gridSize = [4, 5];

  const cardsNeeded = (gridSize[0] * gridSize[1]) / 2;

  const selectedNumbers = sampleSize(
    Array.from({ length: 50 }, (v, i) => i),
    cardsNeeded
  );

  const selectedCards = req?.length
    ? req?.slice(0, cardsNeeded)
    : selectedNumbers.map((num) => {
        return {
          image: `https://ik.imagekit.io/hashku/hashkucdn/storage/soth/main/${num}.jpg/tr:w-500`,
        };
      });

  const doubledCards = [...selectedCards, ...selectedCards];

  const shuffledCards = shuffle(doubledCards);
  gameData.value = shuffledCards.map((card) => {
    return {
      image: card.image,
      selected: false,
    };
  });

  loading.value = false;
};
const locked = ref(false);

const totalMoves = ref(0);
// await new Promise((resolve) => setTimeout(resolve, 700));
const clickCard = async (index) => {
  if (locked.value) {
    return;
  }

  if (gameData.value[index].selected) {
    return;
  }

  locked.value = true;

  gameData.value[index].selected = true;

  currentSelection.value = [...currentSelection.value, index];

  if (currentSelection.value.length > 1) {
    totalMoves.value += 1;
    if (
      gameData.value[currentSelection.value[0]].image !==
      gameData.value[currentSelection.value[1]].image
    ) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      currentSelection.value.forEach((ind) => {
        gameData.value[ind].selected = false;
      });
    }

    currentSelection.value = [];
  }

  locked.value = false;

  if (won.value) {
    await getDailyRecord(true);
  }
};

const won = computed(() => {
  return gameData.value?.length === gameData.value?.filter((card) => card.selected)?.length;
});

const timer = ref(null);

onMounted(() => {
  startNewGame();
  getDailyRecord(false);
});
</script>

<template>
  <div
    class="h-full bg-cover flex items-center justify-center bg-center relative flex-col bg-ch1cream-light"
    :style="{
      'background-image': `url('${require('@/assets/backgrounds/yacht-v1.jpg')}')`,
    }"
  >
    <NavigationNew mode="light" />

    <Breadcrumbs
      :links="[
        { routeName: 'Home', label: 'Home' },
        { label: 'Arcade', routeName: 'Arcade' },
        { label: 'Memory Match' },
      ]"
      class="mt-8"
    />

    <div class="flex-grow max-w-full px-4" v-if="user">
      <div
        class="bg-white block nav-shadow px-2 py-2 z-nav text-xs text-right bg-cover rounded-lg mb-4"
      >
        <div class="flex justify-center">
          <div class="flex items-center">
            <div class="italic text-black mr-2">Today's Medallion:</div>
            <template v-if="dailyRecord">
              <img :src="require('@/assets/memory/icon-winnings.png')" class="w-4 ml-1 mr-1" />
              <div class="text-ch1blue font-bold">Collected!</div>
            </template>
            <template v-else>
              <div class="text-ch1blue font-bold">Not collected</div>
            </template>
          </div>
        </div>
      </div>
      <div class="mb-12 text-left w-full p-4 md:p-8 bg-white rounded-xl box-shadow-std">
        <div class="w-full">
          <!-- <div class="font-display mb-6 text-gray-500 italic">
            Pass the time while you wait for more poker with a game of memory!
          </div> -->
          <div class="flex justify-center">
            <div class="text-center">
              <div class="flex justify-between items-center mb-4">
                <div class="text-3xl font-black font-brother text-ch1blue">Memory Match</div>
                <div class="text-xl font-brother font-bold mb-2">Total Moves: {{ totalMoves }}</div>
              </div>
              <div v-if="won" class="font-black font-brother text-2xl">You won!</div>
              <div
                class="text-sm font-brother underline text-ch1blue mb-4 cursor-pointer"
                @click="startNewGame"
                v-if="won"
              >
                Start a new game
              </div>
              <div class="grid gap-2 grid-cols-4 md:grid-cols-5" v-if="gameData">
                <CardMemory
                  v-for="(card, c) in gameData"
                  :key="c"
                  :image="card.image"
                  :selected="card.selected"
                  @click="clickCard(c)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="flex-grow flex items-center justify-center">
        <div
          class="p-8 m-4 w-full max-w-md bg-white rounded-xl box-shadow-std text-center flex items-center justify-center flex-col flex-grow"
        >
          <div class="mb-6 font-brother">You must be logged in to play our memory match game.</div>
          <div>
            <router-link
              :to="{ name: 'Login', query: { from: encodeURI('/arcade/memory') } }"
              class="btn btn-sm"
            >
              Login
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="pcss">
/* @media (min-width: 1100px) {
  .leaderboard-modal {
    width: 1000px;
  }
} */
</style>
